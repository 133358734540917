const md = require('markdown-it')();
const dedent = require('dedent-js');
var mila = require('markdown-it-link-attributes');

md.use(mila, {
  attrs: {
    target: '_blank',
    rel: 'noopener',
  },
});

const ProgramData = [
  {
    title: 'Prérequis et travail préparatoire',
    shortTitle: 'Travail préparatoire',
    iconName: 'travailplanet',
    colorCode: '#DC9628',
    text: md.render(
      dedent`Il y a 3 prérequis importants à valider avant de pouvoir suivre la formation :
        - Obtenir un score minimal de 65% à notre test de logique (vous recevrez le test en [postulant](/postuler)).
        - Suivre [cette formation en ligne gratuite](/apprendre-a-coder-gratuitement/) que nous avons spécialement créée pour préparer nos futurs élèves.
        - Être équipé d'un ordinateur (MacBook recommandé) pour suivre la formation.`
    ),
    shortText:
      'Préparez votre entrée en la matière avec une initiation aux HTML, CSS et JavaScript.',
    hashtag: ['#Motivation', '#Travail', '#Détermination', '#Préparation'],
    skills: ['HTML', 'CSS', 'JS'],
    courses: [
      'rncp6-fullTime',
      'rncp6-partTime',
      'rncp6-alternance',
      'rncp5-e-learning-landing',
      'rncp6-partTime-landing',
    ],
    duration: {
      'rncp6-partTime-landing': 'environ 40 heures',
    },
  },
  {
    title: 'Prérequis et travail préparatoire',
    iconName: 'travailplanet',
    colorCode: '#DC9628',
    text: md.render(
      dedent`Il y a 3 prérequis importants à valider avant de pouvoir suivre la formation :
        - Posséder une connexion Internet.
        - Suivre [cette formation en ligne gratuite](/apprendre-a-coder-gratuitement/) que nous avons spécialement créée pour préparer nos futurs élèves.
        - Être équipé d'un ordinateur (MacBook recommandé) pour suivre la formation.`
    ),
    hashtag: ['#Motivation', '#Travail', '#Détermination', '#Préparation'],
    skills: ['HTML', 'CSS', 'JS'],
    courses: ['rncp5-e-learning'],
  },
  {
    title: 'Les bases du JavaScript',
    iconName: 'JSplanet',
    colorCode: '#868C9B',
    text: md.render(
      dedent`Vous commencerez à coder vos premières lignes de JavaScript avec Node.js. Le Terminal, les variables, conditions, boucles, fonctions et callbacks n’auront plus aucun secret pour vous. Ici, pas de magie, vous devrez maîtriser tous ces concepts qui seront les fondations pour la suite de la formation. Votre volonté et votre endurance seront mises à rude épreuve, il faudra être tenace et volontaire. Pendant ce module, vous redeviendrez des enfants à qui l'on apprend une nouvelle langue. C'est une étape essentielle, si vous voulez devenir Développeur et savoir débugger du code.`
    ),
    shortText: `Commencez à coder vos premières lignes d'algo en JavaScript !`,
    hashtag: ['#Terminal', '#JavaScript', '#Node.js'],
    skills: ['Terminal', 'JS', 'Node.js'],
    courses: [
      'rncp6-fullTime',
      'rncp6-partTime',
      'rncp6-alternance',
      'rncp6-fullTime-landing',
      'rncp6-partTime-landing',
      'rncp6-alternance-landing',
    ],
    duration: {
      'rncp6-fullTime': '2 semaines',
      'rncp6-partTime': '60 heures',
      'rncp6-alternance': '2 semaines',
    },
  },
  {
    title: 'Les bases du JavaScript',
    shortTitle: 'Les bases du JavaScript (70h)',
    iconName: 'JSplanet',
    colorCode: '#868C9B',
    text: md.render(
      dedent`Vous commencerez à coder vos premières lignes d'algo en JavaScript. Le Terminal, les variables, conditions, boucles, fonctions et callbacks n’auront plus aucun secret pour vous. Ici, pas de magie, vous devrez maîtriser tous ces concepts qui seront les fondations pour la suite de la formation. Votre volonté et votre endurance seront mises à rude épreuve, il faudra être tenace et volontaire. Pendant ce module, vous redeviendrez des enfants à qui l'on apprend une nouvelle langue. C'est une étape essentielle, si vous voulez devenir Développeur et savoir débugger du code.`
    ),
    shortText: `Commencez à coder vos premières lignes d'algo en JavaScript !`,
    hashtag: ['#Terminal', '#JavaScript'],
    skills: ['Terminal', 'JS'],
    courses: ['rncp5-e-learning', 'rncp5-e-learning-landing'],
    duration: {
      'rncp5-e-learning': '70 heures',
    },
  },
  {
    title: 'Serveur / API / Bases de Données',
    iconName: 'serveurplanet',
    colorCode: '#F9C38A',
    text: md.render(
      dedent`Une fois l'agorithmique digérée, vous serez en mesure de comprendre le fonctionnement d'un serveur. Commencera alors le véritable apprentissage du backend grâce auquel vous créerez des API ([Application Programming Interface](https://fr.wikipedia.org/wiki/Interface_de_programmation)). Seront abordés les concepts avancés comme l'authentification des utilisateurs, la sécurité, l'envoi d'email automatisé avec [Mailgun](https://www.mailgun.com/), la gestion de bases de données avec MongoDB, l'upload de fichiers avec [Cloudinary](https://cloudinary.com/), le paiement en ligne avec [Stripe](https://stripe.com/fr), l'hébergement avec [Northflank](https://www.northflank.com/), etc.`
    ),
    shortText: "Vous serez en mesure de comprendre le fonctionnement d'un serveur.",
    hashtag: ['#Express', '#Mongoose', '#MongoDBAtlas', '#Northflank'],
    skills: ['Express', 'Northflank', 'Cloudinary', 'MongoDB', 'Stripe', 'Github', 'Git'],
    mainSkills: ['Express', 'MongoDB', 'Github'],
    courses: [
      'rncp6-fullTime',
      'rncp6-partTime',
      'rncp6-alternance',
      'rncp6-fullTime-landing',
      'rncp6-partTime-landing',
      'rncp6-alternance-landing',
    ],
    duration: {
      'rncp6-fullTime': '1 semaine',
      'rncp6-partTime': '48 heures',
      'rncp6-alternance': '1 semaine',
    },
  },
  {
    title: 'Intégration HTML5 / CSS3',
    iconName: 'integrationplanet',
    colorCode: '#92A8DD',
    text: md.render(
      dedent`Le HTML et le CSS sont des langages incontournables pour la création de pages Web. Vous apprendrez les meilleures pratiques de mise en page, d’optimisation et de référencement. Vous serez amenés à créer des projets qui vous permettront de comprendre le concept de DOM ([Document Object Model](https://fr.wikipedia.org/wiki/Document_Object_Model)) et d'implémenter vos premiers scripts côté frontend, grâce à VanillaJS. Vous apprendrez à répliquer différents sites comme [Marmiton](https://www.marmiton.org/) ou [Trip Advisor](https://www.tripadvisor.fr/) par exemple.`
    ),
    shortText: 'Vous apprendrez les meilleures pratiques de mise en page et d’optimisation Web.',
    hashtag: ['#HTML', '#CSS', '#Responsive', '#VanillaJS', '#Netlify'],
    skills: ['HTML', 'CSS', 'JS', 'Netlify'],
    mainSkills: ['HTML', 'CSS', 'JS'],
    courses: [
      'rncp6-fullTime',
      'rncp6-partTime',
      'rncp6-alternance',
      'rncp6-fullTime-landing',
      'rncp6-partTime-landing',
      'rncp6-alternance-landing',
    ],
    duration: {
      'rncp6-fullTime': '1 semaine',
      'rncp6-partTime': '48 heures',
      'rncp6-alternance': '1 semaine',
    },
  },
  {
    title: 'Interfaces Web dynamiques',
    iconName: 'siteplanet',
    colorCode: '#D75A4A',
    durationFullTime: '3 semaines',
    durationPartTime: '96 heures',
    text: md.render(
      dedent`[React](https://fr.reactjs.org/) est la [librairie](https://fr.wikipedia.org/wiki/Biblioth%C3%A8que_logicielle) JavaScript la plus utilisée et la plus demandée sur le marché du Développement Web. Basée sur un système de composants, elle permet de créer rapidement des interfaces ultra-puissantes. Elle a été créée par Facebook et a été adoptée par les plus grandes entreprises tech (Facebook, Instagram, WhatsApp, Netflix, Dropbox, Airbnb, Paypal, Salesforce, Tesla, Uber, Bolt, Deliveroo, leboncoin, etc). Pendant ce module, nous allons vous apprendre à réaliser des clônes simplifiés de sites Web, tels que [Deliveroo](https://deliveroo.fr/fr/), [Vinted](https://www.vinted.fr/), [Marvel](https://www.marvel.com/), etc.`
    ),
    shortText:
      'Avec React et son système de composants, vous pourrez créer rapidement des interfaces ultra-puissantes',
    hashtag: ['#React', '#Node.js', '#Cloudinary', '#Netlify', '#Northflank'],
    skills: ['React', 'Node.js', 'Cloudinary', 'Netlify', 'Northflank'],
    mainSkills: ['React', 'Netlify', 'Northflank'],
    courses: [
      'rncp6-fullTime',
      'rncp6-partTime',
      'rncp6-alternance',
      'rncp6-fullTime-landing',
      'rncp6-partTime-landing',
      'rncp6-alternance-landing',
    ],
    duration: {
      'rncp6-fullTime': '3 semaines',
      'rncp6-partTime': '96 heures',
      'rncp6-alternance': '3 semaines',
    },
  },
  {
    title: 'Applications Mobile iOS et Android',
    iconName: 'appplanet',
    colorCode: '#88C057',
    text: md.render(
      dedent`[React Native](https://facebook.github.io/react-native/), qui s'inscrit dans la continuité de React, vous permettra de réaliser des applications Mobile « cross-plateform », pour iOS et Android. Vous apprendrez notamment à développer un clône de Airbnb afin d'aborder les concepts de navigation, géolocalisation, stockage mémoire, live tchat, etc.`
    ),
    shortText:
      'React Native vous permettra de réaliser des applications Mobile « cross-plateform », pour iOS et Android.',
    hashtag: ['#ReactNative', '#Expo', '#Node.js', '#Cloudinary'],
    skills: ['React Native', 'Expo', 'Node.js', 'Cloudinary'],
    mainSkills: ['React Native', 'Expo', 'Cloudinary'],
    courses: [
      'rncp6-fullTime',
      'rncp6-partTime',
      'rncp6-alternance',
      'rncp6-fullTime-landing',
      'rncp6-partTime-landing',
      'rncp6-alternance-landing',
    ],
    duration: {
      'rncp6-fullTime': '1 semaine',
      'rncp6-partTime': '48 heures',
      'rncp6-alternance': '1 semaine',
    },
  },
  {
    title: `Création d'un MVP pour une startup`,
    iconName: 'mvpplanet',
    colorCode: '#ADD7DB',
    text: md.render(
      dedent`Au Reacteur, nous misons beaucoup sur la réalisation de projets concrets. Pendant ce dernier module, nous allons vous faire rencontrer des startups qui ont besoin de développer leur MVP ([Minimum Viable Product](https://www.lereacteur.io/blog/comment-creer-application-mobile/)). Vous serez en charge de développer leurs produits, par groupe de 3 à 5 personnes. Ce sera pour vous une première expérience professionnelle qui vous amènera à travailler en équipe afin de répondre à une demande client. Nous travaillons avec différents incubateurs qui nous sourcent des profils de porteurs de projets.`
    ),
    shortText:
      "Réalisez un projet concret en développant, en groupe, le MVP (Minimum Viable Product) d'une startup.",
    hashtag: ['#GestionDeProjet', '#Startup', '#Agile', '#BackOffice', '#Retool'],
    skills: ['React', 'React Native', 'Node.js', 'Retool'],
    mainSkills: ['React', 'React Native', 'Retool'],
    courses: ['rncp6-fullTime', 'rncp6-alternance', 'rncp6-fullTime-landing'],
    duration: {
      'rncp6-fullTime': '2 semaines',
      'rncp6-alternance': '2 semaines',
    },
  },
  {
    title: 'Demo Day',
    iconName: 'demodayplanet',
    colorCode: '#F3D55B',
    text: md.render(
      dedent`Une présentation des projets de fin d'études (Demo Day) sera organisée le dernier jour. Le but de cet événement est de pouvoir montrer ce que vous avez été capable de réaliser pendant le bootcamp. Aux côtés des startupers, vous présenterez (si vous le souhaitez, on ne force personne) votre MVP lors d'une présentation qui aura lieu en live au Reacteur. Ce sera pour vous la possibilité de vous initier à la prise de parole et de rencontrer possiblement de futurs recruteurs et/ou partenaires.`
    ),
    hashtag: ['#Startups', '#DemoDay'],
    skills: [],
    courses: ['rncp6-fullTime', 'rncp6-alternance'],
    duration: {
      'rncp6-fullTime': 'Le dernier jour du bootcamp',
      'rncp6-alternance': 'Le dernier jour du bootcamp',
    },
  },
  {
    title: 'Stage en entreprise (facultatif) rémunéré',
    iconName: 'internshipplanet',
    colorCode: '#DC9628',
    text: md.render(
      dedent`Suite à votre formation au Reacteur, vous bénéficierez, si vous le souhaitez, d'une convention de stage afin de vous lancer sereinement dans votre nouvelle vie professionnelle. Ce sera l'occasion pour vous de renforcer et d'approfondir vos connaissances. Nous récoltons quotidiennement les offres de stages dans le secteur du développement Web et Mobile, pour les mettre à disposition de nos élèves. Les stages sont rémunérés environ 1.000 euros par mois et vous pouvez cumulez cette gratification avec vos allocations chômage (si vous êtes à France Travail, ex Pôle Emploi).`
    ),
    hashtag: ['#BackToWork', '#ToTheMoon'],
    courses: ['rncp6-fullTime', 'rncp6-partTime'],
    skills: [],
    duration: {
      'rncp6-fullTime': '6 mois',
      'rncp6-alternance': '6 mois',
    },
  },
  {
    title: 'Périodes en alternance',
    iconName: 'internshipplanet',
    colorCode: '#DC9628',
    text: md.render(
      dedent`Pendant les périodes d'alternance, vous aborderez des notions avancées telles que :
      - TypeScript
      - Backend avancé : tests unitaires, sécurisation d'un serveur, SWR, Prisma, Strapi, etc.
      - React avancé : Redux, Next.js, React Testing Library, Tailwind, création d'une app desktop avec Electron, etc.
      - React Native avancé : WebSockets, déploiement sur les stores, Push Notifications, mises à jour Over the Air (OTA), etc.
      - Utilisation de l'Intelligence Artificielle (OpenAI)
      `
    ),
    shortText:
      'Vous aborderez des notions avancées telles que Typscript, les tests, Next.js, Tailwind, le déploiement sur les stores, Strapi, les Push Notifications, etc.',
    hashtag: ['#BackToWork', '#ToTheMoon'],
    courses: ['rncp6-alternance', 'rncp6-alternance-landing'],
    skills: ['TypeScript', 'Next.js', 'Tailwind', 'Strapi'],
    duration: {
      'rncp6-alternance': 'Rythme : 3 sem. en entreprise, 1 sem. en formation',
      'rncp6-alternance-landing': '3 sem. en entreprise, 1 sem. en formation',
    },
  },
  // RNCP5 e-learning
  {
    title: 'Frontend',
    shortTitle: 'Frontend (70h)',
    iconName: 'integrationplanet',
    colorCode: '#92A8DD',
    text: md.render(
      dedent`Le HTML et le CSS sont des langages incontournables pour la création de pages Web. Vous apprendrez les meilleures pratiques de mise en page, d’optimisation et de référencement. Vous apprendrez à répliquer différents sites comme [Marmiton](https://www.marmiton.org/) ou [Trip Advisor](https://www.tripadvisor.fr/) par exemple.`
    ),
    shortText: 'Vous apprendrez les meilleures pratiques de mise en page et d’optimisation Web.',
    hashtag: ['#HTML', '#CSS', '#Responsive'],
    skills: ['HTML', 'CSS', 'JS'],
    courses: ['rncp5-e-learning', 'rncp5-e-learning-landing'],
    duration: {
      'rncp5-e-learning': '70 heures',
    },
  },
  {
    title: 'Backend',
    shortTitle: 'Backend (70h)',
    iconName: 'serveurplanet',
    colorCode: '#F9C38A',
    text: md.render(
      dedent`Une fois l'agorithmique digérée lors du premier module, vous serez en mesure de comprendre le fonctionnement d'un serveur. Grâce au framework [Strapi](https://strapi.io/), vous apprendrez les bases du backend, la création et personnalisation des routes HTTP, les bases de données [SQLite](https://www.sqlite.org/) et [PostgreSQL](https://www.postgresql.org/), les principes d'authentification et autorisation, le paiement en ligne, les bases de données et l'hébergement du serveur.`
    ),
    shortText: "Vous serez en mesure de comprendre le fonctionnement d'un serveur.",
    hashtag: ['#Strapi', '#SQLite', '#PostgreSQL'],
    skills: ['Strapi', 'SQLite', 'PostgreSQL'],
    courses: ['rncp5-e-learning', 'rncp5-e-learning-landing'],
    duration: {
      'rncp5-e-learning': '70 heures',
    },
  },
  {
    title: 'Vue.js',
    shortTitle: 'Vue.js (175h)',
    iconName: 'siteplanet',
    colorCode: '#D75A4A',
    text: md.render(
      dedent`[Vue.js](https://vuejs.org/) est un framework JavaScript progressif qui facilite la création d'interfaces utilisateur interactives pour les applications Web. Il se distingue par sa simplicité et sa flexibilité, vous offrant un moyen efficace de construire des applications modernes à l'aide de composants réutilisables.`
    ),
    shortText:
      'Vous apprendrez à créer des interfaces utilisateur interactives pour les applications Web.',
    hashtag: ['#Vue.js', '#Netlify'],
    skills: ['Vue.js', 'Netlify'],
    courses: ['rncp5-e-learning', 'rncp5-e-learning-landing'],
    duration: {
      'rncp5-e-learning': '175 heures',
    },
  },
  {
    title: 'Préparation au projet professionnel',
    shortTitle: 'Projet professionnel (35h)',
    iconName: 'demodayplanet',
    colorCode: '#F3D55B',
    text: md.render(
      dedent`Préparer votre projet professionnel :
      - rédiger un CV et une lettre de motivation efficaces
      - bien se préparer pour un entretien
      - comment répondre aux questions fréquentes
      - optimiser sa présence en ligne en utilisant les réseaux`
    ),
    shortText: 'Conseils CV, lettre de motivation, entretien, présence en ligne.',
    hashtag: ['#LinkedIn'],
    skills: ['LinkedIn', 'CV', 'Entretien'],
    courses: ['rncp5-e-learning', 'rncp5-e-learning-landing'],
    duration: {
      'rncp5-e-learning': '35 heures',
    },
  },
  {
    title: 'Stage en entreprise (facultatif)',
    iconName: 'internshipplanet',
    colorCode: '#DC9628',
    text: md.render(
      dedent`Suite à votre formation au Reacteur, vous bénéficierez, si vous le souhaitez, d'une convention de stage afin de passer votre titre RNCP de Développeur Web et Web Mobile. Nous attirons votre attention sur le fait que le coût du passage du titre n'est pas inclus dans le prix de la formation. Si vous souhaitez passer votre titre, nous vous invitons à nous communiquer cette information lors de votre inscription.`
    ),
    hashtag: ['#BackToWork', '#ToTheMoon'],
    courses: ['rncp5-e-learning', 'rncp5-e-learning-landing'],
    skills: [],
    duration: {
      'rncp5-e-learning': '2 mois',
    },
  },
  // FULL-STACK
  {
    title: 'Concevoir une application Web ou Mobile',
    iconName: 'travailplanet',
    colorCode: '#DC9628',
    text: md.render(
      dedent`- Analyse des fonctionnalités et identification des tâches de développement (veille technologique et réglementaire, étude des besoins utilisateurs, évaluation des contraintes réglementaires : RGPD, accessibilité, éco-conception, mise en place de protocoles de sécurité des données)
    - Conception d'interfaces utilisateur interactives (conception de maquettes et prototypes, prise en compte de l’Ecoconception, RGPD et accessibilité, justification des choix de conception)`
    ),
    courses: ['full-stack'],
  },
  {
    title: 'Piloter un projet de développement d’application Web ou Mobile',
    iconName: 'JSplanet',
    colorCode: '#868C9B',
    text: md.render(
      dedent`- Planification du projet en contexte Agile (identification des ressources humaines et des tâches nécessaires au projet, association des activités aux parties prenantes concernées, planification des itérations et des sprints, coordination fluide tout au long du cycle de développement, mise en place de la méthode Agile, intégration d’un outil de suivi pour évaluer l’avancement des tâches en temps réel, résolution rapide des obstacles rencontrés)
    - Gestion de la qualité (définition des KPI, élaboration d’une approche de tests, élaboration de stratégie de versioning et de collaboration)`
    ),
    courses: ['full-stack'],
  },
  {
    title: `Développer le back-end sécurisée d'une application Web ou Mobile`,
    iconName: 'serveurplanet',
    colorCode: '#F9C38A',
    text: md.render(
      dedent`- Développement de l’API d’une application web ou mobile (rédaction des lignes de code, conception de la base de données (SQL ou NoSQL), implémentation des routes et des contrôleurs, sécurisation de l'API)
    - Gestion des fonctionnalités serveur (documentation de l'API, manipulation de données dans la base de données, mise en place de tests)`
    ),
    courses: ['full-stack'],
  },
  {
    title: `Développer le front-end d'une application Web ou Mobile`,
    iconName: 'integrationplanet',
    colorCode: '#92A8DD',
    text: md.render(
      dedent`- Développement Web ou Mobile - en codant, ou en utilisant un framework, ou des outils No-Code - (choix d’un langage spécifique, d’un framework ou d’outils Low-code/No-Code, intégration de l'expérience utilisateur (UX), création d’une interface en responsive design, respect des bonnes pratiques de sécurité)
    - Gestion des fonctionnalités utilisateur (documentation de l’application, détection et correction des problèmes de fonctionnement et des bogues)`
    ),
    courses: ['full-stack'],
  },
  {
    title: `Déployer une application Web ou Mobile`,
    iconName: 'demodayplanet',
    colorCode: '#F3D55B',
    text: md.render(
      dedent`- Déploiement et automatisation (mise en production dans une démarche DevOps, automatisation du déploiement)
    - Optimisation des performances de l’application (gestion proactive des performances et des erreurs, définition d’axes d’amélioration)`
    ),
    courses: ['full-stack'],
  },
];

export default ProgramData;
